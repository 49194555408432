import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import BlogList from "../components/BlogList"
import Heading from "../components/Heading"

const pageQuery = graphql`
  {
    posts: allGraphCmsPost(sort: { order: DESC, fields: publishedAt }) {
      nodes {
        excerpt
        publishedAt
        slug
        tags
        title
        content {
          markdownNode {
            childMdx {
              timeToRead
            }
          }
        }
        coverImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 400, quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        author {
          name
          title
          picture {
            localFile {
              childImageSharp {
                fixed(width: 40, quality: 90) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`

const Blog = () => {
  const { posts } = useStaticQuery(pageQuery)
  return (
    <Layout>
      <SEO
        meta={{
          title: "Hallowed Hollows | Blog",
          description: "Hallowed Hollows Blog Page",
        }}
      />
      <div className="px-5">
        <Heading>B's Blog</Heading>
        <p className="text-gray-500 text-center leading-10 text-xl">
          Where an ordinary life meets extraordinary dreams
        </p>
      </div>
      <BlogList posts={posts.nodes} />
    </Layout>
  )
}

export default Blog
