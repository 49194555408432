import React from "react"
import PropTypes from "prop-types"
import BlogCard from "./BlogCard"

export default function BlogList({ posts }) {
  return (
    <section className="text-gray-700 body-font overflow-hidden">
      <div className="container px-5 py-24 mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          {posts.map(post => {
            return <BlogCard post={post} key={post.slug} />
          })}
        </div>
      </div>
    </section>
  )
}

BlogList.propTypes = {
  posts: PropTypes.array.isRequired,
}
